import * as React from 'react';

import Layout from '../containers/Layout';
import Seo from '../components/Seo';
import useTranslation from '../hooks/useTranslation';
import Heading from '../components/Heading';
import Content from '../containers/Content';

function Contact({ pageContext: { currentPath } }) {
  const { t, translations, locale } = useTranslation();

  function RenderContent({ TYPE, VALUE }) {
    switch (TYPE) {
      case 'EMAIL':
        return (
          <a href={`mailto:${VALUE}`} className="underline hover:no-underline">
            {VALUE}
          </a>
        );
      case 'PHONE':
        return (
          <a
            href={`tel:${VALUE.replace(/\s/g, '')}`}
            className="underline hover:no-underline"
          >
            {VALUE}
          </a>
        );
      case 'TEXT':
        return <p>{VALUE}</p>;
      default:
        return null;
    }
  }

  return (
    <Layout background="hero" title={t('SEO.CONTACT.TITLE')}>
      <Seo title={t('SEO.CONTACT.TITLE')} location={currentPath} lang={locale}>
        <link rel="preconnect" href="https://www.google.com/maps" />
      </Seo>

      <Content title={t('CONTACT.TITLE')}>
        <div>
          {translations.CONTACT.TEXT.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>

        <p dangerouslySetInnerHTML={{ __html: t('CONTACT.DESCRIPTION') }} />

        <div>
          <Heading level="h3" visualLevel="h6" weight="bold">
            {t('CONTACT.OPENING_HOURS_TITLE')}
          </Heading>
          <ul>
            {translations.CONTACT.OPENING_HOURS.map((text, index) => (
              <li key={index}>
                <p>{text}</p>
              </li>
            ))}
          </ul>
        </div>

        <ul>
          {translations.CONTACT.CONTENT.map((content, index) => (
            <li key={index}>
              <RenderContent {...content} />
            </li>
          ))}
        </ul>

        <iframe
          loading="lazy"
          title="Magnet Lounge-i asukoht"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2028.4645772931367!2d24.75226121596312!3d59.44200400902545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692936696e80d8d%3A0xf2c716513ddd2d42!2sPoordi+3%2C+Kesklinn%2C+Kesklinna%2C+10151+Harju+maakond!5e0!3m2!1set!2see!4v1504093701707"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          className="w-full h-96"
        ></iframe>
      </Content>
    </Layout>
  );
}

export default Contact;
